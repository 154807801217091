<template>
  <div>
    <DataTable
        class="mt-5"
        :paginator="false"
        :sortable="true"
        stripedRows
        :loading="isLoading"
        :value="currentTableState.tableData"
        sortField="equipment.next_calibration"
        sortOrder="-1"
        selectionMode="single"
        @row-select="onRowSelect"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[1, 2, 5, 10, 20]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :lazy="false"
    >
      <!-- Standard columns -->
      <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :sortable="col.sortable"
          :headerStyle="{ width: col.width }">
        <!-- Custom slot content within Column if needed -->
        <template v-if="col.custom" #body="slotProps">
          <slot :name="col.slotName" :row-data="slotProps.data"/>
        </template>
      </Column>
      <template #empty>
        No records found.
      </template>
    </DataTable>
  </div>
</template>

<!--        :rows="currentTableState.rowsPerPage"-->
<!--        :totalRecords="currentTableState.totalRecords"-->
<!--        :sortField="currentTableState.sortField"-->
<!--        :sortOrder="currentTableState.sortOrder"-->
<!--        @row-click="onRowClick"-->
<!--        @page="onPageChange"-->
<!--        @sort="onSort"-->
<script>
import {mapState, mapActions} from "vuex";
import equipment from "@/components/Contacts/Equipment.vue";

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  computed: {
    equipment() {
      return equipment
    },
    ...mapState("scheduleManage", {
      currentTableState: state => state.tableStates[state.currentTable],
      isLoading: state => state.isLoading,
    }),
  },
  methods: {
    ...mapActions("scheduleManage", ["fetchTableData", "updateTableSettings", "updateSortOptions"]),

    onRowSelect(event) {
      this.$emit("row-select", event);
    },
    onRowClick(event) {
      this.$emit("row-click", event);
    },
    onPageChange(event) {
      this.updateTableSettings({
        rows: event.rows,
        page: event.page,
      });
    },
    onSort(event) {
      this.updateSortOptions({
        field: event.sortField,
        order: event.sortOrder,
      });
    },
  },
  watch: {
    'currentTableState.customer_id': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetchTableData();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchTableData();
  },
};
</script>
